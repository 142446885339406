import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 4";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "going-to-production"
    }}>{`Going to production`}</h1>
    <p>{`You've built a cool app, time to put it in production! We'll make it available to the world and ensure you don't spoil production data next time you try something.`}</p>
    <h2 {...{
      "id": "prod-vs-dev-on-backend"
    }}>{`Prod vs. Dev on backend`}</h2>
    <Vimeo id="400644102" mdxType="Vimeo" />
    <h3 {...{
      "id": "use-dev-and-prod-configs"
    }}>{`use dev and prod configs`}</h3>
    <Vimeo id="400644477" mdxType="Vimeo" />
    <p>{`We use this helpful bash script to run `}<inlineCode parentName="p">{`yarn deploy`}</inlineCode>{` and `}<inlineCode parentName="p">{`yarn deploy prod`}</inlineCode>{` in the future.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`STAGE=\${1:-dev}
yarn build
NODE_ENV=$STAGE sls deploy --stage $STAGE
`}</code></pre>
    <h3 {...{
      "id": "see-separate-deploy"
    }}>{`see separate deploy`}</h3>
    <Vimeo id="400644589" mdxType="Vimeo" />
    <h2 {...{
      "id": "prod-on-frontend"
    }}>{`Prod on frontend`}</h2>
    <p>{`The tricky part here is telling the webapp how to connect to different versions of the backend. I'll show you 3 approaches with the final one that worked for me.`}</p>
    <p>{`Gatsby encourages `}<inlineCode parentName="p">{`.env`}</inlineCode>{` files, which only work when you compile locally.`}</p>
    <p>{`Zeit encourages "zeit secrets", which only work during build time.`}</p>
    <p>{`We end up with JSON config files that always work, but aren't the most secure since you check them into your code.`}</p>
    <Vimeo id="400644732" mdxType="Vimeo" />
    <h2 {...{
      "id": "with-gatsby-env-approach"
    }}>{`With Gatsby .env approach`}</h2>
    <h3 {...{
      "id": "connect-proddev-build-time-graphql"
    }}>{`connect prod/dev build-time graphql`}</h3>
    <Vimeo id="400645008" mdxType="Vimeo" />
    <h3 {...{
      "id": "runtime-env-configs"
    }}>{`runtime .env configs`}</h3>
    <Vimeo id="400645353" mdxType="Vimeo" />
    <h2 {...{
      "id": "setup-zeit-manual-deploys"
    }}>{`setup zeit manual deploys`}</h2>
    <Vimeo id="400648337" mdxType="Vimeo" />
    <h2 {...{
      "id": "using-zeit-secrets"
    }}>{`Using Zeit secrets`}</h2>
    <Vimeo id="400646018" mdxType="Vimeo" />
    <h2 {...{
      "id": "alias-to-prod-domain"
    }}>{`alias to prod domain`}</h2>
    <Vimeo id="400646330" mdxType="Vimeo" />
    <h2 {...{
      "id": "move-runtime-config-to-static-queries"
    }}>{`move runtime config to static queries`}</h2>
    <Vimeo id="400646651" mdxType="Vimeo" />
    <h3 {...{
      "id": "use-json-files-to-feed-configs"
    }}>{`use json files to feed configs`}</h3>
    <Vimeo id="400647044" mdxType="Vimeo" />
    <h2 {...{
      "id": "it-works-in-prod-yay"
    }}>{`it works in prod, yay`}</h2>
    <Vimeo id="400647286" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      